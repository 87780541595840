import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Countdown from "../components/fundraiser/countdown"
import fundraiser from "../styles/fundraiser/fundraiser.module.sass"
import RadialChart from "../components/fundraiser/radial-chart"
import cx from "classnames"
import Img from "gatsby-image"
import { format } from "date-fns"
import Button from "react-bootstrap/Button"

export const Fundraiser = ({ title, date, desc, path, coverImg, tags }) => (
  <div className="pb-4">
    <Link to={path} className="no-underline" id="path">
      <div className="grow row">
        <div className="col-12 col-sm-5 mt-3">
          <Img
            fluid={coverImg.childImageSharp.fluid}
            className="shadow"
            objectFit="cover"
            style={{ width: "100%", height: "100%", maxHeight: 220 }}
          />
        </div>
        <div className="col-12 col-sm-7 mt-3">
          <h3 className="mb-1 mt-0">{title}</h3>
          <h6 className="mb-1 mt-0">{format(new Date(date), "iii, dd MMM yyyy")}</h6>
          <p className="mt-0 mb-1">{desc}</p>
        </div>
      </div>
    </Link>
  </div>
)

export const Donation = ({ datetime, msg, name, value }) => {

  let diff, unit;
  const dt = Date.parse(datetime);
  const now = new Date();

  diff = parseInt(Math.abs(dt - now)/1000);
  unit = "s";
  let calculated = false;
  if(diff>59 && !calculated){
    diff = parseInt(diff/60);
    unit = "m";
  } else {calculated = true;}
  if(diff>59 && !calculated){
    diff = parseInt(diff/60);
    unit = "h";
  } else {calculated = true;}
  if(diff>23 && !calculated){
    diff = parseInt(diff/24);
    unit = "d";
  } else {calculated = true;}
  if(diff>6 && !calculated){
    diff = parseInt(diff/7);
    unit = "w";
  } else {calculated = true;}
  if(diff>3 && !calculated){
    diff = parseInt(diff/4);
    unit = "mo";
  } else {calculated = true;}
  if(diff>11 && !calculated){
    diff = parseInt(diff/12);
    unit = "y";
  }
  
  const formattedDiff = diff + unit;

  return (
    <div className="row p-md-0 m-0 col-12 col-sm-4 col-md-12 pb-2">
      <p className="col-10 mb-0 p-0" style={{fontWeight: "bold"}}>{name}</p>
      <p className="col-2 text-right p-0 mb-0">{formattedDiff}</p>
      <p className="col-12 mb-1 p-0">{msg}</p>
      <h4 className="col-12 p-0" style={{fontWeight: "bold", fontFamily: "avenir-book"}}>{value}</h4>
    </div>
  )
}

export default ({ data }) => {
  const charityData = data.AccountData
  let { edges } = data.allMdx // data.markdownRemark holds our post data

  return (
    <div style={{overflowX: "hidden"}}>
      <Layout>
        <SEO title="Fundraisers" />
        <div className="container-small row">
          <div className="col-sm-12 text-center pt-3">
            <h1 className={fundraiser.title}><b>Kilimanjaro 2023</b></h1>
            <p className={fundraiser.desc}>Climbing the world's tallest freestanding mountain to raise money for <a href="https://www.digdeep.org.uk/">Dig Deep</a><span className="d-none d-md-inline">, a charity aimed at giving villages in rural Kenya access to clean water</span>.</p>
            <Countdown date="2023-08-21"/>
          </div>
          <div className="row mt-md-4 mb-3 d-flex justify-content-center">

            <div className="d-flex pt-2 pt-md-3 justify-content-md-end justify-content-center">
              <RadialChart progress={charityData.pct}/>
            </div>

            <div className="col-md-4 pt-md-4 mt-md-2 text-center text-md-left">
              <div className={cx("d-flex d-md-block d-lg-block justify-content-md-end justify-content-center mb-3 mb-md-0", fundraiser.mwContainer)}>
                <div className="row flex-nowrap col-6 col-md-12 d-flex justify-content-end justify-content-md-start">
                  <div className="float-right float-md-left px-3">
                    <h5 className="mb-1 text-nowrap">Total Raised</h5>
                    <h3 style={{fontWeight: "bold", fontFamily: "avenir-book"}} className="mb-3">{charityData.totalRaised}</h3>
                  </div>
                  <div className="px-3 float-right d-none d-md-block">
                    <h5 className="mb-1 text-nowrap">Donors</h5>
                    <h3 style={{fontWeight: "bold", fontFamily: "avenir-book"}} className="mb-3">{charityData.totalDonors}</h3>
                  </div>
                </div>
                <div className="row col-6 col-md-12">
                  <div className="float-center px-3">
                    <h5 className="mb-1">Target</h5>
                    <h3 style={{fontWeight: "bold", fontFamily: "avenir-book"}} className="mb-3">
                      {charityData.target}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4 mb-md-0 col-12 justify-content-center">
              <h3 className="col-12 text-center">Want to support<span className="d-none d-sm-inline"> or find out more</span>?</h3>
              <Button className="btn-primary" href="https://dig-deep.enthuse.com/pf/jake-thomson">CLICK HERE!</Button>
            </div>

            <div className="m-0 p-md-0 col-12 col-md-4 order-md-first justify-content-end is-grey-md">
              <div className={cx("row col-12 m-0 float-right pb-4", fundraiser.mwContainer)}>
                <h4 className="my-3 col-12 text-center text-nowrap">Recent donations</h4>
                {charityData.donors
                .map((item) => (
                  <Donation
                    {...item}
                    key={item.name+item.datetime}
                  />
                ))}
              </div>
            </div>
          </div>

          
        </div>
        <div className="container-small row mt-4 pt-md-4 is-grey-md-alt">
          <div className="col-12"
            style={{
              margin: '0 auto',
              maxWidth: 1000,
              padding: '0px 1.0875rem 1.45rem',
              paddingTop: 0,
            }}>
            <h4 className="col-12 text-center text-md-left">Latest Fundraisers</h4>
            <div className="col-md-12">
            {edges
              .map((item) => (
                <Fundraiser
                  {...item.node.frontmatter}
                  key={item.node.frontmatter.title}
                />
              ))}
          </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  {
    allMdx(filter: {frontmatter: {public: {eq: true}, type: {eq: "Fundraiser"}}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            type
            title
            desc
            tags
            date
            path
            coverImg {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    AccountData: charityData {
    target
    pct
    totalDonors
    totalRaised
    donors {
      datetime
      msg
      name
      value
    }
  }
  }
`