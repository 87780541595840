import React, { Component } from 'react'
import PropTypes from 'prop-types'
import countdownCss from "../../styles/fundraiser/countdown.module.sass"
import TextTransition from './text-transition'

class Countdown extends Component {
  constructor(props) {
    super(props);

    const date = this.calculateCountdown(this.props.date);
    this.state = date
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState(date) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  render() {
    const countDown = this.state;

    return (
      <div className={countdownCss.container}>
        <span className={countdownCss.col}>
          <span className={countdownCss.colElement}>
            <strong>
              <section className="inline">
                {`${this.addLeadingZeros(countDown.days)}`.split("").map((n, i) => (
                  <TextTransition
                    key={i}
                    order={+n}
                    text={n}
                    // springConfig={presets.gentle}
                    inline
                  />
                ))}
              </section>
            </strong>
              <span>{countDown.days === 1 ? 'Day' : 'Days'}</span>
          </span>
        </span>

        <span className={countdownCss.col}>
          <span className={countdownCss.colElement}>
            <strong>
              <section className="inline">
                {`${this.addLeadingZeros(countDown.hours)}`.split("").map((n, i) => (
                  <TextTransition
                    key={i}
                    order={+n}
                    text={n}
                    // springConfig={presets.gentle}
                    inline
                  />
                ))}
              </section>
            </strong>
            <span>Hours</span>
          </span>
        </span>


        <span className={countdownCss.col}>
          <span className={countdownCss.colElement}>
            <strong>
              <section className="inline">
                {`${this.addLeadingZeros(countDown.min)}`.split("").map((n, i) => (
                  <TextTransition
                    key={i}
                    order={+n}
                    text={n}
                    // springConfig={presets.gentle}
                    inline
                  />
                ))}
              </section>
            </strong>
            <span>Min</span>
          </span>
        </span>

        <span className={countdownCss.col}>
          <span className={countdownCss.colElement}>
            <strong>
              <section className="inline">
                {`${this.addLeadingZeros(countDown.sec)}`.split("").map((n, i) => (
                  <TextTransition
                    key={i}
                    text={n}
                    // springConfig={presets.gentle}
                    inline
                  />
                ))}
              </section>
            </strong>
            <span>Sec</span>
          </span>
        </span>
      </div>
    );
  }
}

Countdown.propTypes = {
  date: PropTypes.string.isRequired
};

Countdown.defaultProps = {
  date: new Date()
};

export default Countdown;